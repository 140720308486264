












































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import { GetBillList } from "../../Api/Basics/index"
import Stroage from "../../util/Storage"
import { BillListType } from "../../Type/index"
import { UserStore } from "../../store/modules/User"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface BillType<T=string>{
    loadingShow:boolean;
    RightIcon:T;
    userId:T|number;
    token:T;
    data:{ [propsData:number]:BillListType };
    ListState:boolean[];
    KeyList:number|T[];

    handleTitState(index:number):void;
    handleState(state:number):void;
    handleToPathSuc(data:BillListType):void;
    handleChangeState(index:number):void;
    handleUpData():void;
    handleFilterData(data:any[]):void;
    handleListState(data:Array<T|number>):void;
    handleError(str:T):void;
}

@Component({ name:"Bill",components:{ HeadTop,ListBottom } })
export default class Bill extends ZoomPage implements BillType{
    RightIcon = require("../../assets/icon/Basics/Right.png");
    userId:number|string = ""
    token = ""
    data:{ [propsData:number]:BillListType }= {}
    ListState:boolean[] = []
    KeyList:number|string[] = []
    loadingShow = true
    UpDownBool = true

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")

        if( UserStore.GetBillList.length ){
            this.loadingShow = false
            this.handleFilterData( UserStore.GetBillList )
        }else{
            this.handleUpData()
        }
    }

    handleTitState( index:number ){
        switch( index ){
           case 1:
               return "代取快递-代取费"
           case 2:
               return "万能互助-帮助费"
           default:
               return "其他支付-费用"
        }
    }

    handleState(state:number){
        switch ( Number(state) ) {
           case 1:
               return "收入金额"
           case 2:
               return "支出金额"
           case 3:
               return "冻结资金"
           case 4:
               return "解冻资金"
       }
    }

    handleToPathSuc(data:BillListType){
        this.$router.push({
            path:"/",
            query:{
               orderId:data.orderId as string
            }
        })
    }

    handleChangeState(index:number){
        if( this.ListState.length ){
           let ListState :boolean[] = []
           this.ListState.forEach( (item,idx)=>{
               if( index == idx ){
                   item = !item
               }else{
                   item = false
               }
               ListState.push(item)
           } )
           this.ListState = ListState
        }
    }

    handleUpData(){
        let date = new Date(),
        year = date.getFullYear(),
        moth = date.getMonth() + 1,
        day = date.getDate()
        GetBillList({
            userId:this.userId,
            token:this.token
        },{
            startDate:`${year}-1-1`,
            endDate:`${year}-${moth}-${day}`
        }).then( (res:any)=>{
            this.UpDownBool = false
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.handleFilterData( res.data )
                UserStore.SetBillList( res.data )
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleFilterData( data:Array<any> ){
       let One:BillListType[] = [], Two:BillListType[] = [], Three:BillListType[] = [], Four:BillListType[] = [], five:BillListType[] = [], Six:BillListType[] = [], Seven:BillListType[] = [], Eight:BillListType[] = [], Nine:BillListType[] = [], Ten:BillListType[] = [], eleven:BillListType[] = [], twelve:BillListType[] = [];
       if ( Array.isArray( data ) && data.length  ){ //2020-08-30 11:03:34  createTime
           data.forEach( (item,index)=>{
               if ( !Boolean(item.createTime) )return;
               let month = String( item.createTime ).split("-")[1];
               switch ( Number( month ) ) {
                   case 1:
                       One.push( item )
                       break;
                   case 2:
                       Two.push( item )
                       break;
                   case 3:
                       Three.push( item )
                       break;
                   case 4:
                       Four.push( item )
                       break;
                   case 5:
                       five.push( item )
                       break;
                   case 6:
                       Six.push( item )
                       break;
                   case 7:
                       Seven.push( item )
                       break;
                   case 8:
                       Eight.push( item )
                       break;
                   case 9:
                       Nine.push( item )
                       break;
                   case 10:
                       Ten.push( item )
                       break;
                   case 11:
                       eleven.push( item )
                       break;
                   case 12:
                       twelve.push( item )
                       break;
               }
           } )
           let Data:any = {} // 过滤过的数据
           let FilterTit = [One,Two,Three,Four,five,Six,Seven,Eight,Nine,Ten,eleven,twelve]
           FilterTit.forEach( (item,index)=>{
               if ( item.length ){
                   Data[ index + 1 ] = item
               }
           } )
           let KeyArr:string[] = Object.keys( Data )
           KeyArr = KeyArr.sort( ((a:any,b:any)=>b - a) )
           this.KeyList = KeyArr as number|string[]
           this.handleListState( KeyArr )
           this.data =  Data
       }
    }

    handleListState( data:Array<string|number> ){
        if( data.length ){
            data.forEach((item,index)=>{
                if( index ){
                    this.ListState.push( false )
                }else{
                    this.ListState.push( true )
                }
            })
        }
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
